import React, { useEffect, useRef, useCallback } from "react";

const ComponentBenefitTeaser = ({ uniqueKey, data }) => {
  const productCounterRef = useRef(null);

  const easeOutQuad = (t) => t * (2 - t);

  const animateCounter = useCallback((counter) => {
    const startValue = +counter.textContent || 0; // Start from current value or 0
    const target = +counter.getAttribute("data-target");
    const duration = 2000; // Duration of the animation in ms
    const isCountingUp = target > startValue;
    let start = null;

    const step = (timestamp) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      const t = Math.min(progress / duration, 1);
      const easedProgress = easeOutQuad(t);

      const currentValue = isCountingUp
        ? Math.floor(startValue + easedProgress * (target - startValue))
        : Math.floor(startValue - easedProgress * (startValue - target));

      counter.textContent = currentValue;

      if (progress < duration) {
        window.requestAnimationFrame(step);
      } else {
        counter.textContent = target; // Ensure it ends exactly at the target value
      }
    };

    window.requestAnimationFrame(step);
  }, []);

  const resetAnimationState = useCallback(() => {
    if (productCounterRef.current) {
      const bubbles = productCounterRef.current.querySelectorAll(
        ".product-counter__bubble, .product-counter__bubble--behind, .product-counter__bubble--blurred"
      );
      bubbles.forEach((bubble) => bubble.classList.remove("active"));

      const counter = productCounterRef.current.querySelector(
        ".product-counter__value"
      );
      if (counter) counter.textContent = data.start; // Reset the counter
    }
  }, [data.start]);

  const handleMovement = useCallback((deltaX, deltaY) => {
    productCounterRef.current
      ?.querySelectorAll(".product-counter__bubble.active")
      .forEach((bubble) => {
        bubble.style.transform = `translate(${deltaX}px, ${deltaY}px)`;

        const behindBubble = bubble
          .closest(".product-counter")
          ?.querySelector(".product-counter__bubble--behind");
        if (behindBubble) {
          behindBubble.style.transform = `translate(${deltaX * 0.6}px, ${
            deltaY * 0.6
          }px)`;
        }

        const blurredBubble = bubble
          .closest(".product-counter")
          ?.querySelector(".product-counter__bubble--blurred");
        if (blurredBubble) {
          blurredBubble.style.transform = `translate(${deltaX * 0.3}px, ${
            deltaY * 0.3
          }px)`;
        }
      });
  }, []);

  useEffect(() => {
    resetAnimationState(); // Reset animation state on mount/remount

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const bubble = entry.target.querySelector(
              ".product-counter__bubble"
            );
            const behindBubble = entry.target.querySelector(
              ".product-counter__bubble--behind"
            );
            const blurredBubble = entry.target.querySelector(
              ".product-counter__bubble--blurred"
            );
            const counter = entry.target.querySelector(
              ".product-counter__value"
            );

            animateCounter(counter); // Start the counter animation

            if (bubble && behindBubble && blurredBubble) {
              bubble.classList.add("active");
              behindBubble.classList.add("active");
              blurredBubble.classList.add("active");
            }

            observer.unobserve(entry.target); // Stop observing once activated
          }
        });
      },
      { threshold: 0.5 }
    );

    if (productCounterRef.current) {
      observer.observe(productCounterRef.current);
    }

    return () => observer.disconnect();
  }, [animateCounter, resetAnimationState]);

  useEffect(() => {
    const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      const centerX = window.innerWidth / 2;
      const centerY = window.innerHeight / 2;

      const deltaX = (clientX - centerX) * 0.03;
      const deltaY = (clientY - centerY) * 0.03;

      handleMovement(deltaX, deltaY);
    };

    const handleDeviceOrientation = (event) => {
      const { beta, gamma } = event;

      const deltaX = gamma * 0.3;
      const deltaY = beta * 0.3;

      handleMovement(deltaX, deltaY);
    };

    document.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("deviceorientation", handleDeviceOrientation);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("deviceorientation", handleDeviceOrientation);
    };
  }, [handleMovement]);

  return (
    <div
      key={`component_${uniqueKey}`}
      className="component component-product-benefit"
      reversed={data.layout.reverse}
    >
      <div className="component-product-benefit__content">
        <div className="meta-headline">{data.tagline}</div>
        <h2>{data.header}</h2>
        <p>{data.text}</p>
      </div>
      <div className="product-counter" ref={productCounterRef}>
        <div
          className="product-counter__bubble--blurred counter-bubble"
          aria-hidden="true"
        ></div>
        <div
          className="product-counter__bubble--behind counter-bubble"
          aria-hidden="true"
        ></div>
        <div className="product-counter__bubble counter-bubble">
          <div className="product-counter__number">
            <span className="product-counter__value" data-target={data.end}>
              {data.start}
            </span>
            <span className="product-counter__metric">{data.unit}</span>
          </div>
          <span className="product-counter__type">
            <strong>{data.unitCaption1}</strong>
          </span>
          <span className="product-counter__desc">{data.unitCaption2}</span>
        </div>
      </div>
    </div>
  );
};

export default ComponentBenefitTeaser;
