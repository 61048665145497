import React, { useEffect } from "react";

const ComponentHubspotForm = ({ uniqueKey, data }) => {
  const darkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
  useEffect(() => {
    // hubspot form script tag
    const script = document.createElement("script");
    script.src = "//js-eu1.hsforms.net/forms/embed/v2.js";
    script.type = "text/javascript";
    script.async = true;
    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: data?.portalId,
          formId: darkMode ? data?.formIdDark : data?.formId,
          target: `#form_${uniqueKey}`,
        });
      }
    };
    document.body.appendChild(script);

    // remove script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, [uniqueKey, data, darkMode]);

  return (
    <div
      key={`component_${uniqueKey}`}
      className="component component-hubspot-form"
    >
      <h2>{data.headline}</h2>
      <div id={`form_${uniqueKey}`} className="hubspot__form"></div>
    </div>
  );
};

export default ComponentHubspotForm;
