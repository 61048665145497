import { BlocksRenderer } from "@strapi/blocks-react-renderer";

const ComponentParagraph = ({ uniqueKey, data }) => {
  const content = data.content;
  // Layout properties
  const layout = data.layout || {};
  const reverse = layout.reverse || false;
  const layoutType = layout.layout || "";

  return (
    <div
      key={`component_${uniqueKey}`}
      className="component component-paragraph"
      reversed={reverse}
      layout={layoutType}
    >
      <BlocksRenderer content={content} />
    </div>
  );
};

export default ComponentParagraph;
