import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import config from "./config";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const getInitialLocale = () => {
    const pathLocale = window.location.pathname.split("/")[1];
    return pathLocale && pathLocale.length === 2 ? pathLocale : "de";
  };

  const [currentLocale, setCurrentLocale] = useState(getInitialLocale());
  const [availableLocales, setAvailableLocales] = useState([]);
  const [navigationOptions, setNavigationOptions] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchOptions = async (locale) => {
    try {
      const response = await fetch(
        `${config.apiBaseUrl}/api/page-navigation?locale=${locale}&populate[content]=populate&populate[mainNavigation][populate][pages][populate][0]=page&populate[footerNavigations][populate][pages][populate][0]=page&populate[impressumPage][populate][pages][populate][0]=page&populate[contactPage][populate][pages][populate][0]=page`
      );
      if (!response.ok) {
        throw new Error("Navigation not found");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching navigation:", error);
      throw error;
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchOptions(currentLocale)
      .then((data) => {
        setNavigationOptions(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [currentLocale]);

  const changeLocale = useCallback((locale) => {
    setCurrentLocale(locale);
  }, []);

  const updateLocales = useCallback((locales) => {
    setAvailableLocales(locales);
  }, []);

  return (
    <LanguageContext.Provider
      value={{
        currentLocale,
        availableLocales,
        navigationOptions,
        changeLocale,
        updateLocales,
        setAvailableLocales,
        loading,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
};
