import React, { useMemo } from "react";

const ComponentLogoGrid = ({ uniqueKey, data, customClass }) => {
  // Ensure logos is always an array (avoids errors)
  const logos = data?.logos?.data?.map((item) => ({
    id: item.id,
    svg: item.attributes.SVG,
    link: item.attributes.url,
  })) || [];

  // Helper to extract symbol id from SVG string
  const extractSymbolId = (svgString) => {
    const match = svgString.match(/id="([\w-]+)"/);
    return match ? match[1] : "";
  };

  // Helper to shuffle the array order
  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  // Shuffle once per page load
  const shuffledLogosTop = useMemo(() => shuffleArray(logos), [logos]);
  const shuffledLogosBottom = useMemo(() => shuffleArray(logos), [logos]);

  // Early return AFTER hooks to prevent conditional hook calls
  if (!data) return null;

  const { header } = data;

  return (
    <div
      key={`component_${uniqueKey}`}
      className={`component component-logo-grid ${customClass}`}
    >
      <h2 className="component component-logo-grid__headline">{header}</h2>
      <div className="component-logo-grid__wrapper">
        <div className="component-logo-grid__group">
          {shuffledLogosTop.map((logo) => (
            <a
              key={logo.id}
              href={logo.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg>
                <use xlinkHref={`#${extractSymbolId(logo.svg)}`} />
              </svg>
            </a>
          ))}
        </div>

        <div aria-hidden="true" className="component-logo-grid__group">
          {shuffledLogosTop.map((logo) => (
            <a
              key={logo.id}
              href={logo.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg>
                <use xlinkHref={`#${extractSymbolId(logo.svg)}`} />
              </svg>
            </a>
          ))}
        </div>
      </div>

      {/* Second row with logos */}
      <div className="component-logo-grid__wrapper component-logo-grid--reverse">
        <div className="component-logo-grid__group">
          {shuffledLogosBottom.map((logo) => (
            <a
              key={logo.id}
              href={logo.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg>
                <use xlinkHref={`#${extractSymbolId(logo.svg)}`} />
              </svg>
            </a>
          ))}
        </div>

        <div aria-hidden="true" className="component-logo-grid__group">
          {shuffledLogosBottom.map((logo) => (
            <a
              key={logo.id}
              href={logo.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg>
                <use xlinkHref={`#${extractSymbolId(logo.svg)}`} />
              </svg>
            </a>
          ))}
        </div>
      </div>

      {/* SVG with all symbols */}
      <svg style={{ display: "none" }} xmlns="http://www.w3.org/2000/svg">
        <defs>
          {logos.map((logo) => (
            <symbol
              key={logo.id}
              dangerouslySetInnerHTML={{ __html: logo.svg }}
            />
          ))}
        </defs>
      </svg>
    </div>
  );
};

export default ComponentLogoGrid;
