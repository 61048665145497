import { useState, useEffect } from "react";
import config from "../config";
import Link from "./link";

const ComponentHero = ({ uniqueKey, data }) => {
  const [loaded, setLoaded] = useState(false);
  const imageUrl = data.heroImage?.data?.attributes?.url
    ? `${config.apiBaseUrl}${data.heroImage.data.attributes.url}`
    : null;

  useEffect(() => {
    if (imageUrl) {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => setLoaded(true);
    } else {
      setLoaded(true);
    }
  }, [imageUrl]);

  return (
    <div
      key={`component_${uniqueKey}`}
      className={`component component-hero ${loaded ? "loaded" : ""}`}
      style={imageUrl ? { backgroundImage: `url(${imageUrl})` } : {}}
    >
      <h1>{data.title}</h1>
      <div>
        <p>{data.tagline}</p>
        {data.CTA?.label && (
          <Link
            href={
              data.CTA.preferURL && data.CTA.url
                ? data.CTA.url
                : `/${data.CTA.page?.data?.attributes?.slug || ""}`
            }
            isExternal={data.CTA.preferURL && data.CTA.url}
            content={data.CTA.label}
          />
        )}
      </div>
    </div>
  );
};

export default ComponentHero;
