import config from "../config";
import React from "react";
import Link from "./link";

const ComponentContentTabs = ({ uniqueKey, data }) => {
  if (!data) return null;

  // Extract all content keys dynamically
  const contentItems = Object.keys(data)
    .filter((key) => key.startsWith("content") && data[key])
    .map((key) => data[key]);

  return (
    <div
      key={`component_${uniqueKey}`}
      className="component component-content-tab"
      reversed={data.layout.reverse}
    >
      {contentItems.length > 0 && (
        <React.Fragment>
          {/* Render Radio Inputs */}
          {contentItems.map((content, index) => (
            <input
              key={`radio-${index}`}
              className="radiotab"
              name="tab-content"
              id={`tabmulti-${index}`}
              type="radio"
              tabIndex={1}
              defaultChecked={index === 0} // Check the first tab by default
            />
          ))}

          <div className="component-content-tab__intro">
            <div className="meta-headline">{data.baseContent.tagline}</div>
            <h2>{data.baseContent.header}</h2>
            <p>{data.baseContent.text}</p>
          </div>

          {/* Render Tabs */}
          <div className="component-content-tab__tabs">
            {contentItems.map((content, index) => (
              <label
                className="label"
                key={`tab-label-${content.id}`}
                htmlFor={`tabmulti-${index}`}
              >
                <span className="product-name">{content.header}</span>
              </label>
            ))}
          </div>

          {/* Render Images */}
          <div className="component-content-tab__image">
            {contentItems.map((content) => {
              return (
                <div className="panel" key={`image-${content.id}`}>
                  <figure className="component component-figure">
                    <img
                      src={`${config.apiBaseUrl}${content.image?.data?.attributes?.url}`}
                      alt={content.image?.data?.attributes?.alternativeText}
                    />
                    {content.image?.data?.attributes?.caption && (
                      <figcaption>
                        {content.image?.data?.attributes.caption}
                      </figcaption>
                    )}
                  </figure>
                </div>
              );
            })}
          </div>

          {/* Render Content Panels */}
          <div className="component-content-tab__content">
            {contentItems.map((content) => {
              const ctaLabel = content.CTA?.label;
              const isExternal = content.CTA?.preferURL && content.CTA?.url;
              const ctaHref =
                content.CTA?.preferURL && content.CTA?.url
                  ? content.CTA.url
                  : `/${content.CTA?.page?.data?.attributes?.slug}`;

              return (
                <div className="panel" key={`content-${content.id}`}>
                  <div
                    className="component component-paragraph"
                    layout="width100"
                  >
                    <p>{content.text}</p>
                    {ctaLabel && (
                      <Link
                        href={ctaHref}
                        isExternal={isExternal}
                        content={ctaLabel}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default ComponentContentTabs;
