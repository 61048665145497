import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { useCookieContext } from "../CookieContext";
import config from "../config";
import { useLanguage } from "../LanguageContext";

const COOKIE_KEY = "analyticsConsent";
const content = config.cookieBanner;

const CookieBanner = () => {
  const { showBanner, setShowBanner } = useCookieContext();
  const { currentLocale } = useLanguage();

  useEffect(() => {
    const consent = localStorage.getItem(COOKIE_KEY);
    if (consent === null) {
      setShowBanner(true);
    } else if (consent === "accepted" && config.googleAnalyticsId) {
      ReactGA.initialize(config.googleAnalyticsId);
    }
  }, [setShowBanner]);

  const handleConsent = (consent) => {
    localStorage.setItem(COOKIE_KEY, consent);
    setShowBanner(false);

    if (consent === "accepted" && config.googleAnalyticsId) {
      ReactGA.initialize(config.googleAnalyticsId);
    }
  };

  if (!showBanner) return null;

  return (
    <div className="cookie-banner">
      <p>{content[currentLocale].text}</p>
      <button onClick={() => handleConsent("accepted")} className="accept">
        {content[currentLocale].yes}
      </button>
      <button onClick={() => handleConsent("declined")} className="decline">
        {content[currentLocale].no}
      </button>
    </div>
  );
};

export default CookieBanner;
