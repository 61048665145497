import Link from "./link";

const ComponentAccordion = ({ uniqueKey, data }) => {
  // CTA properties
  const cta = data.CTA || {};
  const ctaLabel = cta.label || "";
  const externalURL = cta.url;
  const preferURL = cta.preferURL || false;
  const slug = cta.page?.data?.attributes?.slug || "";

  const ctaHref = preferURL && externalURL ? externalURL : `/${slug}`;
  const isExternal = preferURL && externalURL;

  return (
    <section
      key={`component_${uniqueKey}`}
      className="component component-accordion"
    >
      <h2>{data.title}</h2>
      {data.faqs?.data?.map((faq, index) => (
        <details key={`faq_${index}`}>
          <summary>
            <strong>{faq.attributes.question}</strong>
          </summary>
          <div className="component-accordion__content">
            <p>{faq.attributes.answer}</p>
          </div>
        </details>
      ))}
      {ctaLabel && (
        <Link href={ctaHref} isExternal={isExternal} content={ctaLabel} />
      )}
    </section>
  );
};

export default ComponentAccordion;
