import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import config from "../config";
import Link from "./link";

const ComponentHeroHomepage = ({ uniqueKey, data }) => {
  const [videoLoaded, setVideoLoaded] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener("canplaythrough", () => {
        setVideoLoaded(true);
      });
    }
  }, []);

  // CTA properties
  const cta = data.CTA || {};
  const ctaLabel = cta.label || "";
  const externalURL = cta.url;
  const preferURL = cta.preferURL || false;
  const slug = cta.page?.data?.attributes?.slug || "";

  const ctaHref = preferURL && externalURL ? externalURL : `/${slug}`;
  const isExternal = preferURL && externalURL;

  return (
    <>
      <div
        key={`component_${uniqueKey}`}
        className="component component-hero component-hero--homepage"
      >
        <h1>{data.header}</h1>
        <div>
          <p>{data.claim}</p>
          {ctaLabel && (
            <Link href={ctaHref} isExternal={isExternal} content={ctaLabel} />
          )}
        </div>
      </div>
      <motion.video
        ref={videoRef}
        className="component-hero__video"
        initial={{ opacity: 0 }}
        animate={{ opacity: videoLoaded ? 1 : 0 }}
        transition={{ duration: 0.5, ease: "easeOut" }}
        width="320"
        height="240"
        loop
        autoPlay
        muted
        playsInline
      >
        <source
          src={`${config.apiBaseUrl}${data.video.data.attributes.url}`}
          type="video/mp4"
        />
      </motion.video>
    </>
  );
};

export default ComponentHeroHomepage;
