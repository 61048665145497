import React, { useEffect, useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import ReactGA from "react-ga4";
import { LanguageProvider, useLanguage } from "./LanguageContext";
import { CookieProvider } from "./CookieContext";
import NavHeader from "./components/nav-header";
import NavFooter from "./components/nav-footer";
import CookieBanner from "./components/cookie-banner";
import ComponentsPage from "./pages/Components";
import HomePage from "./pages/Home";
import JobsPage from "./pages/Jobs";

const pageVariants = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -10 },
};

const PageWrapper = ({ children, isFirstLoad }) => (
  <motion.div
    variants={pageVariants}
    initial={isFirstLoad ? false : "initial"} // No animation on first load
    animate="animate"
    exit="exit"
    transition={{ duration: 0.3, ease: "easeInOut" }}
  >
    {children}
  </motion.div>
);

const AppContent = () => {
  const { navigationOptions, loading, currentLocale } = useLanguage();
  const location = useLocation();
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    const consent = localStorage.getItem("analyticsConsent");
    if (consent === "accepted" && typeof ReactGA.ga === "function") {
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    }
  }, [location]);

  useEffect(() => {
    setIsFirstLoad(false); // After first render, disable the first-load state
  }, []);

  return (
    <>
      {navigationOptions && <NavHeader options={navigationOptions} />}
      <article className="main" role="main">
        <AnimatePresence
          mode="wait"
          onExitComplete={() => window.scrollTo(0, 0)}
        >
          <Routes location={location} key={location.pathname}>
            <Route
              path="/"
              element={<Navigate to={`/${currentLocale}`} replace />}
            />
            <Route
              path="/:locale"
              element={
                <PageWrapper isFirstLoad={isFirstLoad}>
                  <HomePage />
                </PageWrapper>
              }
            />
            <Route
              path="/:locale/jobs/*"
              element={
                <PageWrapper isFirstLoad={isFirstLoad}>
                  <JobsPage />
                </PageWrapper>
              }
            />
            <Route
              path="/:locale/*"
              element={
                <PageWrapper isFirstLoad={isFirstLoad}>
                  <ComponentsPage />
                </PageWrapper>
              }
            />
            <Route
              path="*"
              element={
                <PageWrapper isFirstLoad={isFirstLoad}>
                  <ComponentsPage />
                </PageWrapper>
              }
            />
          </Routes>
        </AnimatePresence>
      </article>
      {navigationOptions && <NavFooter options={navigationOptions} />}
    </>
  );
};

const App = () => (
  <HelmetProvider>
    <LanguageProvider>
      <CookieProvider>
        <BrowserRouter>
          <AppContent />
          <CookieBanner />
        </BrowserRouter>
      </CookieProvider>
    </LanguageProvider>
  </HelmetProvider>
);

export default App;
