import config from "../config";
import React from "react";
import Link from "./link";

const ComponentProductTabs = ({ uniqueKey, data }) => {
  if (!data) return null;

  return (
    <div
      key={`component_${uniqueKey}`}
      className="component component-product-tabs"
    >
      {data.products?.data?.map((product, index) => {
        // CTA properties
        const cta = product.attributes.CTA || "";
        const ctaLabel = cta.label || "";
        const externalURL = cta.url;
        const preferURL = cta.preferURL || false;
        const slug = cta.page?.data?.attributes?.slug || "";

        const ctaHref = preferURL && externalURL ? externalURL : `/${slug}`;
        const isExternal = preferURL && externalURL;

        return (
          <React.Fragment key={`product_${index}`}>
            <input
              className="radiotab"
              name="tabs"
              tabIndex="1"
              type="radio"
              id={`tab_${index}`}
              defaultChecked={index === 0}
            />
            <label className="label" htmlFor={`tab_${index}`}>
              <span className="product-name">
                {product.attributes.internalTitle}
              </span>
              <span className="meta">{product.attributes.tagline}</span>
            </label>
            <div className="panel" tabIndex="1">
              <div className="component component-paragraph-image">
                <div>
                  <span className="meta">{product.attributes.tagline}</span>
                  <h2>{product.attributes.productName}</h2>
                  <p>{product.attributes.teaser}</p>
                  {product.attributes.CTA.label && (
                    <Link
                      href={ctaHref}
                      isExternal={isExternal}
                      content={ctaLabel}
                    />
                  )}
                </div>
                <div>
                  <figure>
                    <img
                      src={`${config.apiBaseUrl}${product.attributes?.productImage?.data?.attributes?.url}`}
                      alt={
                        product.attributes?.productImage?.data?.attributes
                          ?.alternativeText
                      }
                    />
                    {product.attributes?.productImage?.data?.attributes
                      ?.caption && (
                      <figcaption>
                        {
                          product.attributes.productImage.data.attributes
                            .caption
                        }
                      </figcaption>
                    )}
                  </figure>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default ComponentProductTabs;
