import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../LanguageContext";
import fetchContentBySlug from "../api";
import ComponentHero from "../components/hero.js";
import Components from "../components/index.js";
import { Helmet } from "react-helmet-async";
import config from "../config";
import { motion } from "framer-motion";

const ComponentsPage = () => {
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [backgroundLoaded, setBackgroundLoaded] = useState(false);
  const navigate = useNavigate();
  const { availableLocales, currentLocale, updateLocales, changeLocale } =
    useLanguage();

  useEffect(() => {
    const fetchContent = async () => {
      const pathname = window.location.pathname;
      const slug =
        pathname.endsWith("/") && pathname !== "/"
          ? pathname.slice(0, -1)
          : pathname;
      const routeSlug = slug.startsWith("/") ? slug.slice(1) : slug;
      const localeInsideSlug = routeSlug.split("/")[0];
      const locale = localeInsideSlug.length === 2 ? localeInsideSlug : "de";

      try {
        const data = await fetchContentBySlug("pages", routeSlug);
        if (!data.data.length) throw new Error("Page not found");

        setContent(data.data[0].attributes);
        updateLocales(
          data.data[0].attributes.localizations.data.map((item) => ({
            locale: item.attributes.locale,
            slug: item.attributes.slug,
          }))
        );
        changeLocale(locale);
      } catch {
        navigate(`/${currentLocale}/404`);
      } finally {
        setLoading(false);
      }
    };

    fetchContent();
  }, [navigate, updateLocales, changeLocale]);

  // preload css background image
  useEffect(() => {
    if (content?.heroImage?.data?.attributes?.url) {
      const bgImage = new Image();
      bgImage.src = `${config.apiBaseUrl}${content.heroImage.data.attributes.url}`;
      bgImage.onload = () => setBackgroundLoaded(true);
    } else {
      setBackgroundLoaded(true); // No background image, show instantly
    }
  }, [content]);

  return (
    <>
      {loading ? (
        <div className="loading-spinner" aria-hidden="true"></div>
      ) : (
        <>
          <Helmet>
            <html lang={currentLocale} />
            <title>{content?.title} | metrilus</title>
            <meta name="description" content={content?.meta_description} />
            <meta property="og:title" content={content?.title} />
            <meta
              property="og:description"
              content={content?.meta_description}
            />
            <meta
              property="og:image"
              content={`${config.apiBaseUrl}${content?.heroImage?.data?.attributes.url}`}
            />
            <meta
              property="og:url"
              content={`${config.baseUrl}${content?.slug}`}
            />
            <meta
              property="og:type"
              content="website"
            />
            <link 
              rel="canonical" 
              href={`${config.baseUrl}${content?.slug}`} 
            />
            <link
              rel="alternate"
              href={`${config.baseUrl}${content?.slug}`} 
              hreflang={currentLocale}
            />

            {availableLocales
              .filter((locale) => locale.locale !== currentLocale)
              .map((locale) => (
                <link
                  rel="alternate"
                  key={locale.locale}
                  href={`${window.location.origin}/${locale.slug}`}
                  hreflang={locale.locale}
                />
              ))}
          </Helmet>

          {loading || !backgroundLoaded ? null : (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, ease: "easeOut" }}
            >
              {content?.title && <ComponentHero data={content} />}
              {content?.content && <Components content={content} />}
            </motion.div>
          )}
        </>
      )}
    </>
  );
};

export default ComponentsPage;
