import config from "../config";

const ComponentQuote = ({ uniqueKey, data }) => {
  if (!data.testimonial.data) return null;

  const { url, alternativeText } =
    data.testimonial.data.attributes?.image?.data?.attributes;
  const { quote, name, jobTitle, company } = data.testimonial.data.attributes;

  return (
    <div
      key={`component_${uniqueKey}`}
      className="component component-testimonial"
      // layout={data.layout}
    >
      <div>
        <blockquote>"{quote}"</blockquote>
        {url && (
          <div className="component-testimonial-avatar">
            <img src={`${config.apiBaseUrl}${url}`} alt={alternativeText} />
          </div>
        )}
        <p className="component-testimonial__author">
          <span className="author-name">{name}, </span>
          <span className="author-job">{jobTitle} </span>
          <span className="author-company">{company}</span>
        </p>
      </div>
    </div>
  );
};

export default ComponentQuote;
