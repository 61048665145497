import React from "react";
import { NavLink } from "react-router-dom";
import { useLanguage } from "../LanguageContext";
import { useCookieContext } from "../CookieContext";
import config from "../config";

const NavFooter = ({ options }) => {
  const { availableLocales, currentLocale } = useLanguage();
  const { setShowBanner } = useCookieContext();

  const NavLinks = (url, title) => {
    try {
      const itemUrl = new URL(url);
      const relativeUrl = itemUrl.pathname;
      return (
        <NavLink to={relativeUrl} className="nav-link" key={title}>
          {title}
        </NavLink>
      );
    } catch (error) {
      console.error("Invalid URL:", url);
      return null;
    }
  };

  const footerData = options?.data?.attributes || {};
  const footerNavigations = footerData?.footerNavigations?.data || [];

  return (
    <footer>
      <NavLink to="/" className="logo">
        metrilus
      </NavLink>

      {/* Language Switcher */}
      <section className="footer-links__languages">
        {availableLocales?.length > 0 && (
          <>
            <h3>{config.footer.languageHeadline[currentLocale]}</h3>
            <span className="lang--current">{currentLocale}</span>
            {availableLocales.map((locale) =>
              NavLinks(
                `${window.location.origin}/${locale.slug}`,
                locale.locale
              )
            )}
          </>
        )}
      </section>

      {/* Contact Section */}
      <section className="footer-links__block">
        <h3>Contact</h3>
        <address>
          {footerData?.street && (
            <>
              {footerData.street} <br />
            </>
          )}
          {footerData?.zip && footerData?.city && (
            <>
              {footerData.zip} {footerData.city} <br />
            </>
          )}
          {footerData?.country && (
            <>
              {footerData.country} <br />
            </>
          )}
          {footerData?.phone && (
            <a href={`tel:${footerData.phone}`}>{footerData.phone}</a>
          )}
          {footerData?.press &&
            NavLinks(footerData.press.url, footerData.press.title)}
        </address>
      </section>

      {/* Footer Navigations */}
      {footerNavigations.map((block, blockIndex) => (
        <section key={blockIndex} className="footer-links__block">
          <h3>{block?.attributes?.title}</h3>
          <ul>
            {block?.attributes?.pages?.data?.map((page, index) => (
              <li key={index}>
                {NavLinks(
                  `${window.location.origin}/${page?.attributes?.slug}`,
                  page?.attributes?.title
                )}
              </li>
            ))}
          </ul>
        </section>
      ))}

      {/* Social Links */}
      <ul className="footer-links__social">
        {footerData?.socialSVG1 && footerData?.socialURL1 && (
          <li>
            <a
              href={footerData.socialURL1}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="YouTube"
              dangerouslySetInnerHTML={{ __html: footerData.socialSVG1 }}
            ></a>
          </li>
        )}
        {footerData?.socialSVG2 && footerData?.socialURL2 && (
          <li>
            <a
              href={footerData.socialURL2}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="LinkedIn"
              dangerouslySetInnerHTML={{ __html: footerData.socialSVG2 }}
            ></a>
          </li>
        )}
      </ul>

      {/* Imprint and Contact Links */}
      <div className="imprint">
        <ul>
          <li>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setShowBanner(true);
              }}
            >
              {config.cookieBanner[currentLocale].footerLink}
            </a>
          </li>
          {footerData?.impressumPage?.data && (
            <li>
              {NavLinks(
                `${window.location.origin}/${footerData.impressumPage.data.attributes.slug}`,
                footerData.impressumPage.data.attributes.title
              )}
            </li>
          )}
          {footerData?.contactPage?.data && (
            <li>
              {NavLinks(
                `${window.location.origin}/${footerData.contactPage.data.attributes.slug}`,
                footerData.contactPage.data.attributes.title
              )}
            </li>
          )}
          <li>
            <span>Metrilus GmbH</span>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default NavFooter;
