import React from "react";
import Link from "./link";

const ComponentDescriptionList = ({ uniqueKey, data }) => {
  const { title, properties } = data.group.data.attributes;
  // CTA properties
  const cta = data.CTA || {};
  const ctaLabel = cta.label || "";
  const externalURL = cta.url;
  const preferURL = cta.preferURL || false;
  const slug = cta.page?.data?.attributes?.slug || "";

  const ctaHref = preferURL && externalURL ? externalURL : `/${slug}`;
  const isExternal = preferURL && externalURL;

  return (
    <div
      key={`component_${uniqueKey}`}
      className="component component-desc-list"
      // layout={data.layout}
    >
      <h2>{title}</h2>
      <dl>
        {properties.map((item) => (
          <React.Fragment key={item.id}>
            {/* Add "title-only" class to <dt> if value is null */}
            <dt
              className={item.value === null ? "title-only" : ""}
              {...(item.indented && { indented: "true" })}
            >
              {item.key}
            </dt>
            {/* Render <dd> only if there is a value */}
            {item.value && <dd>{item.value}</dd>}
          </React.Fragment>
        ))}
      </dl>
      {/* <figcaption>* pieces per hour (pph)</figcaption> */}

      {ctaLabel && (
        <Link href={ctaHref} isExternal={isExternal} content={ctaLabel} />
      )}
    </div>
  );
};

export default ComponentDescriptionList;
