// This config is used by the Gitlab CI *only* when stable UI builds are created
const config = {
  baseUrl: "https://metrilus.com/",
  apiBaseUrl: "https://strapi.metrilus.com/cms",
  googleAnalyticsId: "G-9RCJB7RK4N",
  footer: {
    languageHeadline: {
      de: "Sprachen",
      en: "Languages",
      es: "Sprachen",
      fr: "Sprachen",
    },
  },
  cookieBanner: {
    de: {
      text: "Wir verwenden Cookies für Analysezwecke. Weitere Details finden Sie in unserer Datenschutzerklärung. Akzeptieren Sie?",
      yes: "Ja",
      no: "Nein",
      footerLink: "Cookie Einstellungen",
    },
    en: {
      text: "We use cookies for analytic purposes. You can find more details in our Privacy Policy. Do you accept?",
      yes: "Yes",
      no: "No",
      footerLink: "Cookie Settings",
    },
    es: {
      text: "Wir verwenden Cookies für Analysezwecke. Weitere Details finden Sie in unserer Datenschutzerklärung. Akzeptieren Sie?",
      yes: "Ja",
      no: "Nein",
      footerLink: "Cookie Einstellungen",
    },
    fr: {
      text: "Wir verwenden Cookies für Analysezwecke. Weitere Details finden Sie in unserer Datenschutzerklärung. Akzeptieren Sie?",
      yes: "Ja",
      no: "Nein",
      footerLink: "Cookie Einstellungen",
    },
  },
};
export default config;
