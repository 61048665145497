import { createContext, useContext, useState } from "react";

const CookieContext = createContext();

export const CookieProvider = ({ children }) => {
  const [showBanner, setShowBanner] = useState(false);

  return (
    <CookieContext.Provider value={{ showBanner, setShowBanner }}>
      {children}
    </CookieContext.Provider>
  );
};

export const useCookieContext = () => useContext(CookieContext);
